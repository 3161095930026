.grayTitle {
  color: var(--color-grayWarm);
}

.boldTitle {
  font-weight: 700;
}

.contentTd {
  max-width: 150px;
  white-space: normal;

  div {
    display: inline;
    margin-right: 4px;
  }
}

.modal {
  img {
    max-width: 150px;
    max-height: 150px;
  }
}
