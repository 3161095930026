.wrap {
  overflow: hidden;

  .container {
    width: 100%;
    overflow: hidden;

    .table {
      th,
      td {
        padding-top: 14px !important;
        padding-bottom: 14px !important;
        white-space: wrap;
        padding-left: 2px;
        padding-right: 2px;

        width: 100px;
        min-width: 100px;
        max-width: 100px;
      }

      thead {
        th {
          &:not(:first-child) {
            color: white;
          }
        }
      }
      tbody {
        td {
          &.couponList {
            vertical-align: text-top;

            .couponItem {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 2px;

              .deleteBtn {
                background-color: var(--color-danger);
                color: var(--color-white);
                padding: 2px 6px;
                font-size: 10px;
                border-radius: 6px;
                cursor: pointer;
              }
              .addBtn {
                background-color: var(--color-success);
                color: var(--color-white);
                padding: 2px 6px;
                font-size: 10px;
                border-radius: 6px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
