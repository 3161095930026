.box {
  display: flex;
  align-items: center;
  flex: 1;
  border: 1px solid var(--color-gray6);
  border-radius: 6px;
  overflow: hidden;

  &.focus {
    border: 1px solid var(--color-green);
  }

  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  &.center {
    text-align: center;
  }

  input {
    flex: 1;
    border: none;
    outline: none;
    padding: 4px 8px;
    min-height: 23px;
  }

  .rightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid var(--color-gray5);
    padding: 4px 8px;
  }
}
