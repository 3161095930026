.rewardTable {
  tr {
    border-top: 0 !important;
  }
  th {
    font-size: 10px;
    color: var(--color-gray3);
  }
  td {
    font-size: 13px;
  }
  th,
  td {
    padding: 1px 8px 1px 1px !important;
  }
}
