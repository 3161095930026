.row {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;

  .col {
    justify-content: center;
  }
}
