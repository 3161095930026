.logoImg {
  max-width: 150px;
  max-height: 150px;
  min-width: 50px;
  height: auto;
}

.imgRow {
  display: flex;
  overflow: scroll;
  position: relative;

  > div {
    margin-right: 4px;
    max-width: 150px;
    max-height: 150px;
    position: relative;

    .deleteBtn {
      position: absolute;
      top: 2px;
      right: 2px;
      background-color: var(--color-gray3);
      color: var(--color-white);
      padding: 2px 6px;
      font-size: 10px;
      border-radius: 6px;
    }
  }
}
