.badge {
  background-color: var(--color-success);
  color: var(--color-white);
  font-size: 12px;
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 700;
  border-width: 1px;

  &.total {
    background-color: var(--color-secondary);
    color: var(--color-white);
  }
  &.viewTotal {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
  &.purchase,
  &.success {
    background-color: var(--color-success);
    color: var(--color-white);
  }
  &.advertise {
    background-color: var(--color-blue);
    color: var(--color-white);
  }
  &.info {
    background-color: var(--color-gray8);
    color: var(--color-black);
  }
  &.organic {
    background-color: var(--color-brightGreen);
    color: var(--color-white);
  }
  &.today {
    background-color: var(--color-warning);
    color: var(--color-black);
  }
  &.out {
    background-color: var(--color-danger);
    color: var(--color-white);
  }

  &.dawn,
  &.danger {
    background-color: var(--color-danger);
    color: var(--color-white);
  }
  &.plus,
  &.warning {
    background-color: var(--color-warning);
    color: var(--color-black);
  }

  &.main {
    background-color: var(--color-info);
    color: var(--color-black);
  }

  .remove {
    color: var(--color-black);
    padding-left: 4px;
  }
}

.gray8 {
  color: var(--color-gray8);
}

.gray6 {
  color: var(--color-gray6);
}

.gray4 {
  color: var(--color-gray4);
}

.b_gray3 {
  background-color: var(--color-gray3);
}

.b_blue {
  background-color: var(--color-blue);
}

.gray5 {
  color: var(--color-gray5);
}

.white {
  color: var(--color-white);
}

.error {
  color: var(--color-danger);
}

/* 아래 코드는 추가하지 마세요. CSS 모듈은 자동으로 클래스를 내보냅니다. */
