.badge {
  background-color: var(--color-green);
  font-size: 10px;
  display: inline-flex;
  padding: 4px 6px;
  color: var(--color-white);
  border-radius: 8px;
  font-weight: 700;

  &.active {
    background-color: var(--color-orangeSub);
  }
}

.member {
  display: flex;
  justify-content: space-between;
}

.container {
  padding: 12px;
  border: 1px solid var(--color-gray6);
  margin: 6px 0;
  border-radius: 12px;

  .title {
    font-size: 20px;
    margin-bottom: 12px;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--color-gray6);
  }
  .content {
    font-size: 14px;
    white-space: pre-wrap;
  }
}

.answerContainer {
  margin-top: 12px;

  .title {
    font-size: 14px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
  .content {
    font-size: 14px;
  }
}
