.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .dropdownMenu {
    display: none;
    list-style: none;
    position: absolute;
    background-color: var(--color-white);
    //min-width: 80px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 0; /* 부모 요소의 오른쪽에 맞춰 정렬 */
    top: 30%; /* 버튼 바로 아래에 위치 */

    &.open {
      display: block;
    }

    li {
      padding: 2px 1px;
      font-size: 14px;
      color: var(--color-black);
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  &.open .dropdownMenu {
    display: block;
  }
}
