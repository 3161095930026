.container {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;

  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000080;
  width: 100vw;
  height: 100vh;
}
