.container {
  text-align: center;
}

.title {
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  color: var(--color-gray1);
}

.content {
  margin-top: 4px;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-gray4);
}

.buttonContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 20px 24px 0;
}
