.minus {
  color: var(--color-red);
  font-weight: bold;
}

.summary {
  .title {
    min-width: 70px;
  }
}
.badge {
  background-color: var(--color-warning);
  font-size: 10px;
  display: inline-flex;
  padding: 2px 4px;
  color: var(--color-black);
  border-radius: 4px;
  font-weight: 700;
  margin-bottom: 2px;
  margin-right: 6px;

  &.success {
    background-color: var(--color-success);
  }
}

.summary {
  font-size: 12px;

  .title {
    text-align: right;
    padding-right: 4px;
  }
  td:not(.title) {
    > div {
      display: flex;
      justify-content: space-between;
      gap: 4px;
    }
  }
}
