.container {
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000080;
  width: 100vw;
  height: 100vh;

  &.animate {
    .modalContainer {
      position: relative;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
    }

    .modal {
      position: absolute;
    }
  }

  &.show {
    display: flex;
    position: fixed;

    &.animate {
      &.top .modalContainer {
        top: 0;
        -webkit-transition: opacity 0.2s linear, top 0.2s ease-in;
        -moz-transition: opacity 0.2s linear, top 0.2s ease-in;
        -o-transition: opacity 0.2s linear, top 0.2s ease-in;
        transition: opacity 0.2s linear, top 0.2s ease-in;
      }
      &.bottom .modalContainer {
        bottom: 0;
        -webkit-transition: opacity 0.2s linear, bottom 0.2s ease-in;
        -moz-transition: opacity 0.2s linear, bottom 0.2s ease-in;
        -o-transition: opacity 0.2s linear, bottom 0.2s ease-in;
        transition: opacity 0.2s linear, bottom 0.2s ease-in;
      }
      &.left .modalContainer {
        left: 0;
        -webkit-transition: opacity 0.2s linear, left 0.2s ease-in;
        -moz-transition: opacity 0.2s linear, left 0.2s ease-in;
        -o-transition: opacity 0.2s linear, left 0.2s ease-in;
        transition: opacity 0.2s linear, left 0.2s ease-in;
      }
      &.right .modalContainer {
        right: 0;
        -webkit-transition: opacity 0.2s linear, right 0.2s ease-in;
        -moz-transition: opacity 0.2s linear, right 0.2s ease-in;
        -o-transition: opacity 0.2s linear, right 0.2s ease-in;
        transition: opacity 0.2s linear, right 0.2s ease-in;
      }
    }
  }
  &.hide {
    display: none;

    &.animate {
      &.top .modalContainer {
        top: -100%;
        -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
        -o-transition: opacity 0.3s linear, top 0.3s ease-out;
        transition: opacity 0.3s linear, top 0.3s ease-out;
      }
      &.bottom .modalContainer {
        bottom: -100%;
        -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
        -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
        transition: opacity 0.3s linear, bottom 0.3s ease-out;
      }
      &.left .modalContainer {
        left: -100%;
        -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
        -o-transition: opacity 0.3s linear, left 0.3s ease-out;
        transition: opacity 0.3s linear, left 0.3s ease-out;
      }
      &.right .modalContainer {
        right: -100%;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
      }
    }
  }
}

.modalContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: absolute;
    max-height: 90vh;
    max-width: 80vw;
    background-color: var(--color-white);
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);

    .title {
      display: flex;
      justify-content: space-between;
      padding: 32px 24px 0;
    }

    .content {
      flex: 1;
      height: 100%;
      overflow: auto;
      padding: 20px 24px 24px 24px;
      margin-bottom: 24px;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding: 20px 24px 24px;
    }

    svg:hover {
      cursor: pointer;
    }
  }
}
.container.mobile {
  .modal {
    max-width: unset;
  }
}
