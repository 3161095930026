.sidebar {
  overflow-y: auto; /* 내용 스크롤 가능 */
  scrollbar-width: none; /* Firefox에서 스크롤바 숨김 */
}

.sidebar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge에서 스크롤바 숨김 */
}

:root {
  --sidebar-open-width: 220px;
  --sidebar-close-width: 80px;
}

.pane {
  height: 100vh;
  overflow: hidden;
  background: var(--color-gray7);
}

.subMenuItem {
  position: relative;
}

.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  top: 50%;
  left: 100%;
  transform: translateY(-25%);
}

.headerLeft {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;

  .menuToggle {
    padding: 8px 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.menuToggle {
  //padding: 8px 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray4);
  }
}

.profile {
  position: fixed;
  left: 0;
  bottom: 0;
  justify-content: left;

  .icon {
    width: 20px;
    height: 20px;
    padding: 5px;
    background-color: var(--color-gray7);
    border: 1px solid var(--color-gray6);
    border-radius: 100%;
    vertical-align: middle;
  }

  .title {
    padding-left: 10px;
    vertical-align: sub;
    font-size: 1em;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.mobileMenuToggle {
  display: block;
  position: fixed;
  right: 10px;
  bottom: 10px;
  padding: 10px;
  background-color: var(--color-gray7);
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}

.header {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  //align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: var(--color-white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  //transition: margin-left 0.2s ease-in-out;

  @media (min-width: 769px) {
    &.open {
      margin-left: var(--sidebar-open-width);
    }
    &.close {
      margin-left: var(--sidebar-close-width);
    }
  }
}

.sidebar {
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-gray2);
  //transition: transform 0.3s ease;
  overflow-x: hidden;
  overflow-y: auto;
  color: var(--color-ivory);
  //transition: width 0.2s ease-in-out;

  &.open {
    width: var(--sidebar-open-width);
    transform: translateY(0);

    .menuCount {
      font-size: 12px;
      margin-left: 2px;
    }
  }

  &.close {
    width: var(--sidebar-close-width);

    .sidebarMenu {
      text-align: center;

      .menuItem,
      .menuGroup {
        span {
          display: none;
        }

        .icon {
          margin: 0;
        }
      }
    }
  }

  // 햄버거 버튼
  .hambuger {
    //width: 100%;
    min-width: 30px;
    cursor: pointer;
    //hover
    &:hover {
      color: var(--color-white);
    }
  }

  .sidebarHeader {
    padding: 20px 0;
    display: flex;
    align-items: center; /* 수직 가운데 정렬 */
    justify-content: center; /* 수평 가운데 정렬 */
    gap: 10px;
    //width: 250px;
  }

  .logoSmall {
    max-width: 50px;
    height: auto;
    object-fit: contain; /* 이미지가 영역을 벗어나지 않도록 조정 */
  }

  .logoLarge {
    max-width: 40%;
    height: auto;
    object-fit: contain; /* 이미지가 영역을 벗어나지 않도록 조정 */
  }

  .sidebarMenu {
    list-style: none;
    padding: 0;
    margin: 0;

    .menuItem,
    .menuGroup {
      padding: 12px 15px;
      cursor: pointer;

      .icon {
        min-width: 20px;
        margin-right: 15px;
      }
    }

    .menuItem {
      &.active {
        background-color: var(--color-green);
      }

      :hover {
        color: var(--color-white);
        font-weight: 700;
      }
    }

    .submenuTitle {
      font-size: 16px;
      position: relative;
    }

    .menuGroup {
      position: relative;
      padding: 12px 15px;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        top: 25px;
        right: 20px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #aaa transparent transparent transparent;
        //transition: transform 0.2s ease;
      }

      &.open {
        .subMenu {
          max-height: 100svh;
        }

        &::after {
          transform: translateY(-50%) rotate(-180deg);
        }
      }

      .subMenu {
        list-style: none;
        max-height: 0;
        overflow: hidden;
        //transition: max-height 0.2s ease;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .subMenuItem {
          display: flex;
          align-items: center; /* 수직 가운데 정렬 */
          padding: 10px 0 10px 15px;
          cursor: pointer;

          &.active {
            background-color: var(--color-green);
          }
        }

        :hover {
          color: var(--color-white);
          font-weight: 700;
        }
      }
    }
  }
}

.content {
  flex: 1;
  display: flex;
  position: relative;
  overflow: hidden;

  .sidebarPlaceholder {
    &.open {
      width: var(--sidebar-open-width);
    }

    &.close {
      width: var(--sidebar-close-width);
    }
  }

  .mainContent {
    position: relative;
    flex: 1;
    max-height: 100vh;
    //padding: 2px 12px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (min-width: 769px) {
  .sidebar {
    .sidebarMenu {
    }

    &.close {
      .menuGroup {
        &::after {
          right: 20px;
        }

        &.open {
          padding-bottom: 0;
          //transition: padding 0.2s ease-in-out;

          .subMenu {
            padding: 0;
            margin: 6px 0;
            //transition: max-height 0.2s ease;

            .subMenuItem {
              padding-left: 0px;
              //padding: 6px 0;
              display: flex;
              flex: 1;
              justify-content: center;
              margin: 0;

              .icon {
                padding: 6px 0;
                // 아이콘 크기 조절
              }

              > .icon {
                //font-size: 0.85em;
              }

              // before로 아이콘 추가
              &::before {
                content: "";
                display: inline-block;
                width: 3px;
                height: 3px;
                margin-right: 10px;
                background-color: var(--color-gray7);
                border-radius: 100%;
              }
            }

            // hover시 자식에 있는 span태그에 텍스트를 추출해서 말풍선 형식으로 보여줌
            .subMenuItem:hover {
              // subMenuItem에있는 title을 가져와서 보여줌
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .sidebar {
    z-index: 1;
    transform: translateY(-100%);
    width: 100vw !important;
    //transition: transform 0.3s ease;

    &.open {
      transform: translateY(0);
    }

    .sidebarHeader img {
      display: none;
    }
  }

  .content {
    .sidebarPlaceholder {
      display: none;
    }
  }
}

/* 팝업레이아웃 */
.popupContainer {
  padding: 24px 32px;
}

@media (max-width: 768px) {
  .popupContainer {
    padding: 12px 16px;
  }
}
