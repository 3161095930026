.scoreContainer {
  display: flex;
  justify-content: center;

  .star {
    width: 15px;
    height: 15px;
    margin: 0 2px;
  }
}

.itemName {
  text-align: left;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  flex: 1;
  overflow: hidden;

  .col {
    flex: auto;
    overflow: auto;
  }

  .detailContainer {
    flex: 1;
    min-width: 400px;
    padding: 20px;
    align-items: flex-start;
    font-size: 14px;
    background-color: var(--color-white);

    .member {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-gray6);
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    .optionContainer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background-color: var(--color-gray8);
      padding: 8px 12px;
      border-radius: 12px;

      .item {
        margin: 4px;
        font-size: 12px;

        .category {
          color: var(--color-gray4);
          margin-right: 4px;
        }
        .option {
          color: var(--color-gray1);
        }
      }
      .dot {
        width: 2px;
        height: 2px;
        border-radius: 4px;
        background-color: var(--color-gray5);
        margin: 0 2px;
      }
    }

    .contentContainer {
      padding: 12px;
      border: 1px solid var(--color-gray6);
      margin: 6px 0;
      border-radius: 12px;

      .content {
        white-space: pre-wrap;
        font-size: 14px;
      }
      .photoContainer {
        display: flex;
        margin-top: 16px;
        overflow: auto;

        img {
          margin-right: 4px;
          max-width: 200px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .row {
    flex: 1;
    display: flex;
    margin-bottom: 4px;
    flex-wrap: wrap;

    .col {
      display: flex;
      flex: auto;
    }
    .detailContainer {
      flex: 1;
      min-width: none;
    }
  }
}
