.item {
  width: 100%;
  margin: 0 8px;
  background: var(--color-gray7);
  padding: 8px 0;
  border-radius: 12px;

  .title {
    text-align: center;
    font-size: 16px;
  }

  .content {
    text-align: center;
    font-size: 20px;
    margin-top: 4px;
  }

  .subContent {
    text-align: center;
    font-size: 12px;
    margin-top: 4px;
  }
}

.memberChartContainer {
  height: 210px;
  margin-top: 24px;
  width: 100%;
}

.memberChartTbody {
  .memberChartThead td {
    text-align: center;
    font-weight: 700;
    vertical-align: middle;
  }

  .title td {
    text-align: center;
  }

  td {
    text-align: left;
    font-size: 12px;
    vertical-align: text-top;

    div {
      margin-bottom: 4px;
    }
  }
}

.reviewChartContainer {
  height: 210px;
  margin-top: 24px;
  width: 100%;
}

.signupChartContainer {
  height: 210px;
  margin-top: 24px;
  width: 100%;
}

.dashboardWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  > * {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray6);
    padding: 10px 0px;
    box-sizing: border-box;
  }

}

