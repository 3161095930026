// @components/Tabs/style.module.scss
.tabContainer {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  gap: 4px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  background-color: #f5f5f5;

  &:hover {
    background-color: #e9e9e9;
  }

  &.active {
    background-color: #fff;
    border-bottom: 1px solid #fff;
    margin-bottom: -1px;
    font-weight: bold;
  }
}
