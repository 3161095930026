.container {
  position: relative;
  width: 100%;
  min-width: 130px;
  max-width: 300px;
  user-select: none; /* 드래그 불가 */

  .header {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //border: 1px solid var(--color-gray6);
    //border-radius: 6px;
    //background: var(--color-white);

    &:hover {
      background-color: var(--color-gray8); /* 초기화 기능 시각적 피드백 */
    }

    .placeholder {
      flex-grow: 1;
      font-size: 14px;
      //color: var(--color-gray7);
      cursor: pointer; /* Placeholder도 클릭 가능 */
      transition: color 0.2s ease;
    }

    .arrow {
      cursor: pointer; /* 화살표는 펼치기/접기 */
      padding-right: 8px;
      font-size: 13px;
      color: var(--color-gray5);

      &:hover {
        color: var(--color-gray4);
      }
    }
  }

  .list {
    position: relative;
    margin-top: 4px;
    margin-left: 5px;
    //border: 1px solid var(--color-gray6);
    border-radius: 6px;
    background: var(--color-white);

    .listItem {
      font-size: 14px;
      padding: 6px 2px;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: var(--color-gray6);
      }

      &.selected {
        background-color: var(--color-primary-light);
        font-weight: bold;
      }
    }
  }
}
