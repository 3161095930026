.wrapper {
  display: flex;
  flex: 1;
  height: 100vh;
  min-width: 50vw;
  justify-content: center;
  align-items: center;

  .container {
    display: flex;
    flex: 0 1;
    align-items: center;
    min-width: 60vw;

    .logo {
      flex: 1;
      img {
        max-width: 90vw;
      }
    }
    .content {
      flex: 1;
      max-width: 90vw;

      .title {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: var(--color-gray1);
      }

      .row {
        margin-top: 12px;
        .rowTitle {
          display: block;
          font-size: 14px;
          color: var(--color-gray3);
          margin-bottom: 4px;
        }
      }

      .alert {
        font-size: 12px;
        color: var(--color-red500);
      }
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    margin: 0;
    flex-direction: column;
    justify-content: space-around;

    .container {
      display: block;
    }
  }
}
