.badge {
  background-color: var(--color-success);
  font-size: 10px;
  display: inline-flex;
  padding: 2px 4px;
  color: var(--color-white);
  border-radius: 4px;
  font-weight: 700;
  margin-bottom: 2px;

  &.badgeCount {
    background-color: var(--color-primary);
  }
  &.warning {
    background-color: var(--color-warning);
    color: var(--color-black);
  }
  &.danger {
    background-color: var(--color-danger);
  }
}

.logoImg {
  max-width: 150px;
  max-height: 150px;
  min-width: 50px;
  height: auto;
}

.modalContainer {
  max-width: 600px;
  margin: auto;

  .infoRow {
    .badgeList {
      display: flex;
      margin-top: 4px;
      gap: 4px;
    }
  }

  @media (min-width: 768px) {
    .infoRow {
      flex-wrap: nowrap;
    }
  }
}

.rowData {
  display: block;
}

.badgeImg {
  max-width: 30px;
  max-height: 30px;
  height: auto;
  margin: 0 2px;
}

.minusIcon {
  background-color: var(--color-red);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgCol {
  display: flex;
  flex: 1;

  .detailImg {
    display: flex;
    flex: 1;
    max-width: 240px;
  }
}

.reviewTab {
  flex: 1;
  flex-direction: column;

  .scoreContainer {
    display: flex;
    justify-content: center;

    .star {
      width: 15px;
      height: 15px;
      margin: 0 2px;
    }
  }

  .itemName {
    text-align: left;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .row {
    display: flex;
    flex: 1;
    overflow: hidden;

    .col {
      flex: auto;
      overflow: auto;
    }

    .detailContainer {
      flex: 1;
      min-width: 400px;
      padding: 20px;
      align-items: flex-start;
      font-size: 14px;

      .member {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-gray6);
        padding-bottom: 10px;
        margin-bottom: 10px;
      }

      .optionContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: var(--color-gray8);
        padding: 8px 12px;
        border-radius: 12px;

        .item {
          margin: 4px;
          font-size: 12px;

          .category {
            color: var(--color-gray4);
            margin-right: 4px;
          }
          .option {
            color: var(--color-gray1);
          }
        }
        .dot {
          width: 2px;
          height: 2px;
          border-radius: 4px;
          background-color: var(--color-gray5);
          margin: 0 2px;
        }
      }

      .contentContainer {
        padding: 12px;
        border: 1px solid var(--color-gray6);
        margin: 6px 0;
        border-radius: 12px;

        .content {
          white-space: pre-wrap;
          font-size: 14px;
        }
        .photoContainer {
          display: flex;
          margin-top: 16px;
          overflow: auto;

          img {
            margin-right: 4px;
            max-width: 200px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .row {
      flex: 1;
      display: flex;
      margin-bottom: 4px;
      flex-wrap: wrap;

      .col {
        display: flex;
        flex: auto;
      }
      .detailContainer {
        flex: 1;
        min-width: none;
      }
    }
  }
}
