.span {
  font-size: 11px;
  margin-left: 4px;
}

.content {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prevContainer {
  color: var(--color-gray3);

  :global(.col) {
    min-height: 0;
  }
}

.overflow-ellipsis {
  //max-width: 10px;
  //text-overflow: ellipsis;
}

.button {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
