:root {
  --pagination-height: 30px;
}

.container {
  flex: 1;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .table {
    flex: 1;
    width: 100%;
    max-width: min(100%, 100vw);
    margin-bottom: 1rem;
    vertical-align: bottom;
    border-top: 1px solid var(--color-gray6);
    font-size: 14px;
    text-align: center;
    background-color: var(--color-white);

    thead {
      tr {
        border-bottom: 2px solid var(--color-gray6);
      }
    }

    td,
    th {
      padding: 0.4rem 0.7rem;
    }

    .wrap {
      white-space: pre-wrap;
    }

    tbody {
      th {
        vertical-align: middle;
      }

      td {
        &:global(.title) {
          text-align: left;
        }
        &:global(.content) {
          text-align: right;
        }
      }

      tr {
        border-top: 1px solid var(--color-gray6);

        &:global(.summary) {
          font-weight: 700;
          background: var(--color-gray6);
        }
        &:global(.titleContent) {
          text-align: center;
          font-weight: 700;
          background: var(--color-gray6);
        }

        &:hover {
          background: var(--color-gray7);
        }
      }
    }

    img {
      max-width: 50px;
      max-height: 50px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    .table {
      td,
      th {
        padding: 2px 4px;
      }
    }
  }
}
