.modal {
  img {
    max-width: 200px;
    max-height: 200px;
  }
  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  .minusIcon {
    background-color: var(--color-red);
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
