.gridContainer {
  flex: 1;

  > div {
    width: "100%";
  }

  .editRow {
    background: var(--color-orange);
  }
}

.contentBtnDiv {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
}

.badge {
  background-color: var(--color-success);
  font-size: 10px;
  display: inline-flex;
  padding: 2px 4px;
  color: var(--color-white);
  border-radius: 4px;
  font-weight: 700;
  margin-bottom: 2px;

  &.danger {
    background-color: var(--color-danger);
  }
}
