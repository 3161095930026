.badge {
  background-color: var(--color-success);
  color: var(--color-white);
  font-size: 10px;
  display: inline-flex;
  padding: 2px 4px;
  border-radius: 8px;
  font-weight: 700;

  &.dawn {
    background-color: var(--color-danger);
    color: var(--color-white);
  }
  &.plus {
    background-color: var(--color-warning);
    color: var(--color-black);
  }

  &.subscribeBadge {
    background-color: var(--color-gray3);
    color: var(--color-white);
  }
}

.filterTitle {
  min-width: 120px;
}

.col {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 14px;
}

.minus {
  color: var(--color-red400);
}

.modalCol {
  flex: 1 !important;
  flex-direction: column;
  width: 100%;

  .item {
    flex: 1;
    margin-top: 14px;
    padding: 0 8px;

    .itemTitle {
      max-width: 150px;
      font-weight: 500;
    }

    :global(.col) {
      font-size: 13px;
      align-items: baseline;
      flex: 1;

      &.colWrap {
        flex-wrap: wrap;

        div {
          white-space: nowrap;
        }
      }
    }
  }
}
