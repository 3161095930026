.badge {
  background-color: var(--color-success);
  color: var(--color-white);
  font-size: 10px;
  display: inline-flex;
  padding: 2px 4px;
  border-radius: 8px;
  font-weight: 700;

  &.total {
    background-color: var(--color-secondary);
    color: var(--color-white);
  }
  &.viewTotal {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
  &.purchase,
  &.success {
    background-color: var(--color-success);
    color: var(--color-white);
  }
  &.advertise {
    background-color: var(--color-blue);
    color: var(--color-white);
  }
  &.info {
    background-color: var(--color-gray8);
    color: var(--color-black);
  }
  &.organic {
    background-color: var(--color-brightGreen);
    color: var(--color-white);
  }
  &.today {
    background-color: var(--color-warning);
    color: var(--color-black);
  }
  &.out {
    background-color: var(--color-danger);
    color: var(--color-white);
  }

  &.dawn,
  &.danger {
    background-color: var(--color-danger);
    color: var(--color-white);
  }
  &.plus,
  &.warning {
    background-color: var(--color-warning);
    color: var(--color-black);
  }

  &.main {
    background-color: var(--color-info);
    color: var(--color-black);
  }

  .remove {
    color: var(--color-black);
    padding-left: 4px;
  }
}

.col {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 14px;
}

.modalCol {
  flex: 1 !important;
  flex-direction: column;
  width: 100%;

  .item {
    flex: 1;
    margin-top: 14px;
    padding: 0 8px;

    .itemTitle {
      max-width: 150px;
      font-weight: 500;
    }

    .itemContent {
      ul {
        width: 100%;

        li > div:global(.row) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    :global(.col) {
      font-size: 13px;
      align-items: baseline;
      flex: 1;

      &.colWrap {
        flex-wrap: wrap;

        div {
          white-space: nowrap;
        }
      }
    }
  }
}

.reivewTab {
  .scoreContainer {
    display: flex;
    justify-content: center;

    .star {
      width: 15px;
      height: 15px;
      margin: 0 2px;
    }
  }

  .itemName {
    text-align: left;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .row {
    display: flex;
    flex: 1;
    overflow: hidden;

    .col {
      flex: auto;
      overflow: auto;
    }

    .detailContainer {
      flex: 1;
      min-width: 400px;
      padding: 20px;
      align-items: flex-start;
      font-size: 14px;
      max-width: 100%;

      .member {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-gray6);
        padding-bottom: 10px;
        margin-bottom: 10px;
      }

      .optionContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: var(--color-gray8);
        padding: 8px 12px;
        border-radius: 12px;

        .item {
          margin: 4px;
          font-size: 12px;

          .category {
            color: var(--color-gray4);
            margin-right: 4px;
          }
          .option {
            color: var(--color-gray1);
          }
        }
        .dot {
          width: 2px;
          height: 2px;
          border-radius: 4px;
          background-color: var(--color-gray5);
          margin: 0 2px;
        }
      }

      .contentContainer {
        padding: 12px;
        border: 1px solid var(--color-gray6);
        margin: 6px 0;
        border-radius: 12px;

        .content {
          white-space: pre-wrap;
          font-size: 14px;
        }
        .photoContainer {
          display: flex;
          margin-top: 16px;
          overflow: auto;

          img {
            margin-right: 4px;
            max-width: 200px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .row {
      flex: 1;
      display: flex;
      margin-bottom: 4px;
      flex-wrap: wrap;

      .col {
        display: flex;
        flex: auto;
      }
      .detailContainer {
        flex: 1;
        min-width: unset;
        padding: 20px 0 0 0;
      }
    }
  }
}

.subscribeItem {
  flex: 1;
  padding: 6px 12px;
  border: 1px solid var(--color-gray2);
  border-radius: 16px;

  ul {
    padding: 0;
    margin: 0;
  }
}

.minusIcon {
  background-color: var(--color-red);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plusIcon {
  background-color: var(--color-success);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartAddItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;

  :nth-child(1) {
    flex: auto;
  }
  :nth-child(2) {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 1024px) {
  .infoTab {
    &:global(.row) {
      flex-direction: column;
    }
  }
}
