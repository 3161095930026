@tailwind components;
@tailwind utilities;

:root {
  --shadow-light: 0 2px 4px rgba(0, 0, 0, 0.1);
  --shadow-medium: 0 3px 6px rgba(0, 0, 0, 0.15);
  --shadow-subtle: 0 1px 3px rgba(0, 0, 0, 0.08);
}

a {
  position: relative;
  text-decoration: none;
  color: inherit;

  &::after {
    content: "\f35d"; /* FontAwesome "external-link-alt" icon unicode */
    font-family: "Font Awesome 6 Free"; /* Adjust based on your FontAwesome version */
    font-weight: 900; /* Ensure the correct style (solid icon) is applied */
    margin-left: 4px; /* Add spacing between text and icon */
    font-size: 0.8em; /* Adjust the size of the icon */
    display: inline-block; /* Ensures the icon is inline with the text */
    vertical-align: middle; /* Aligns the icon with the text */
    color: inherit; /* Matches the icon color to the link text color */
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  white-space: nowrap;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-bold {
  font-weight: 700;
}
.break-spaces {
  white-space: break-spaces;
  min-width: 100px;
}

.flex-start {
  justify-content: flex-start;
}
.flex-end {
  justify-content: flex-end;
}

.row {
  flex: 1;
  display: flex;
  margin-bottom: 4px;
  flex-wrap: wrap;

  &.top {
    .col {
      align-items: flex-start;
    }
  }
  &.center {
    .col {
      justify-content: center;
    }
  }

  .col {
    display: flex;
    flex: auto;
    min-height: 28px;
    font-size: 14px;
    align-items: center;

    &.title {
      width: 30%;
      flex: none;
      font-size: 14px;
      font-weight: 700;
      margin-right: 4px;
    }
    &.preline {
      white-space: pre-line;
    }
    &.flexColumn {
      flex-direction: column;
    }

    .buttonCol {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.preline {
  white-space: pre-line;
  word-break: break-word;
}
.preWrap {
  white-space: pre-wrap;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-10 {
  font-size: 10px;
}
.text-11 {
  font-size: 11px;
}
.text-12 {
  font-size: 12px;
}
.text-13 {
  font-size: 13px;
}
.text-14 {
  font-size: 14px;
}
.text-15 {
  font-size: 15px;
}
.text-16 {
  font-size: 16px;
}
.text-17 {
  font-size: 17px;
}
.text-18 {
  font-size: 18px;
}
.text-19 {
  font-size: 19px;
}
.text-20 {
  font-size: 20px;
}

.hide {
  display: none;
}
