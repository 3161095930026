.container {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0;
  }

  .leftSearchCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4px;
    flex-grow: 1; /* 남은 공간을 모두 차지 */
    background: var(--color-white);
    box-shadow: var(--shadow-light);
    padding: 4px;
  }

  .leftFilterCol {
    display: flex;
    flex-direction: row; /* 필터들을 가로로 정렬 */
    flex-wrap: wrap; /* 필터들이 가로로 정렬되도록 함 */
    gap: 4px;
    //padding: 4px;
  }

  .rightCol {
    display: flex;
    flex-wrap: wrap;
    padding: 4px;
    flex-shrink: 0;
    gap: 4px;

  }

  .col {
    min-height: 30px;
  }

  .bottom {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2px 4px;
  }

  @media screen and (max-width: 768px) {
    .row {
      flex-direction: column;
      gap: 12px;
    }

    .leftSearchCol,
    .rightCol {
      //flex: none;
      //width: 100%;
      //padding: 8px;
    }

    .bottom {
      justify-content: stretch;
      gap: 8px;
    }

    .bottom button {
      flex: 1;
      min-width: 120px;
    }
  }
}
