.button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-gray8);
  line-height: 24px;
  color: var(--color-gray1);
  max-height: 100%;
  padding: 6px 12px;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid var(--color-gray6); /* 은은한 회색 테두리 */

  &.greenBtn {
    background: var(--color-green);
    color: var(--color-white);
    font-weight: 700;
    border-color: var(--color-green-dark); /* 초록 버튼의 경우 다른 색상 */
  }
}

.inputLabel {
  flex: 1;
  display: flex;

  input {
    display: none;
  }
}
