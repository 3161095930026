.row {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;

  .filterTitle {
    min-width: 90px;
    display: flex;
  }

  .col {
    justify-content: center;
  }
}

.dateBtn {
  gap: 0;
}

.topSection {
  margin-bottom: 8px;

  .col {
    &.item {
      > div:nth-child(1) {
        background-color: var(--color-gray2);
        color: var(--color-white);
      }
    }
  }
}

.col {
  display: block;
  flex-direction: column;
  margin-bottom: 0;

  &.item {
    min-width: 140px;
    border: 1px solid var(--color-gray7);
    justify-content: space-between;

    > div {
      min-height: 28px;
      line-height: 130%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > div:nth-child(1) {
      background-color: var(--color-gray8);
      border-bottom: 1px solid var(--color-gray7);
      font-weight: 300;
      font-size: 14px;
    }
    > div:nth-child(2) {
      font-weight: 700;
      font-size: 15px;
    }
  }
}

.desc {
  font-size: 14px;
  color: var(--color-red400);
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
