.badge {
  background-color: var(--color-success);
  color: var(--color-white);
  font-size: 10px;
  display: inline-flex;
  padding: 2px 4px;
  border-radius: 8px;
  font-weight: 700;

  &.purchase {
    background-color: var(--color-success);
    color: var(--color-white);
  }
  &.out {
    background-color: var(--color-danger);
    color: var(--color-white);
  }
}
