.tabs {
  display: flex;
  //flex: 1;
  width: 100%;
  border-bottom: 1px solid var(--color-gray6);
  margin-bottom: 8px;
  flex-wrap: wrap;

  .item {
    padding: 4px 12px;
    border-radius: 4px 4px 0 0;
    font-size: 16px;
    color: var(--color-gray3);

    &.active {
      background: var(--color-gray6);
    }
  }
}
