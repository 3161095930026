.flatBoxContainer {
  width: 100%;
  border: 1px solid var(--color-gray6);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
}

.container {
  position: relative;
  width: 100%;
}

.searchInput {
  width: 100%;
  padding: 4px 6px;
  border: 1px solid #ccc;
  box-sizing: border-box; /* 패딩 포함 너비 계산 */
  margin-bottom: 8px;
}

.dropdown {
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
  right: 0;
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.listItem {
  font-size: 12px;
  display: block; /* 한 줄에 하나씩 */
  width: 100%; /* 부모 컨테이너 기준으로 꽉 차게 */
  box-sizing: border-box; /* 패딩 포함 너비 계산 */
  padding: 4px 6px;
  margin: 0; /* 여백 제거 */
  border-bottom: 1px solid #eee; /* 아이템 구분선 추가 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background-color 0.2s;
}

.listItem:last-child {
  border-bottom: none; /* 마지막 아이템 구분선 제거 */
}

.listItem:hover {
  background-color: #f0f0f0;
}

.selected {
  background-color: #e0f7fa;
}

.listWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px; /* 선택된 아이템 간격 */
}

.selectedItem {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  align-items: center;
}

button {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

button:hover {
  background-color: #e0f7fa;
  border-color: #00796b;
  color: #00796b;
}
