.container {
  display: flex;

  .item {
    display: flex;
    margin-right: 8px;

    input[type="radio"] {
      padding: 0;
      margin: 0 4px 0 0;
    }
  }
}
