.container {
  display: flex;
  flex: 1;
  input[type="checkbox"] {
    padding: 0;
    margin: 0;
  }

  span {
    margin-left: 4px;
  }
}

label.toggle {
  width: 56px;
  height: 24px;
  background-color: var(--color-gray7);
  background-image: none;
  border-radius: 2rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;

  input {
    display: none;
  }

  span {
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 6px;
  }

  :before {
    color: var(--color-gray5);
    content: attr(data-off-label);
    display: block;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 24px;
    position: absolute;
    right: 3px;
    margin: 0 0.21667rem;
    top: 0;
    text-align: center;
    min-width: 1.66667rem;
    overflow: hidden;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  :after {
    content: "";
    position: absolute;
    left: 4px;
    background-color: var(--color-gray6);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 2rem;
    height: 18px;
    width: 18px;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &.active {
    background-color: var(--color-green);
    color: var(--color-white);

    span {
      justify-content: flex-start;
    }

    :before {
      color: #fff;
      content: attr(data-on-label);
      right: auto;
      left: 4px;
    }

    :after {
      left: auto;
      right: 4px;
      background-color: var(--color-gray6);
    }
  }
}
