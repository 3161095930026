.pageContainer {
  display: flex;
  right: 0;
  height: var(--pagination-height);
  padding-top: 12px;
  justify-content: center;
  align-items: center;

  .paging {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    .pageItem {
      margin: 0 2px;
      min-width: 28px;
      height: 28px;
      border-radius: 100%;
      border: none;
      background: none;

      &:hover {
        cursor: pointer;
      }

      &.active {
        background: var(--color-green);
        color: var(--color-white);
        font-weight: 700;
      }
    }

    .arrowBtn {
      margin: 0 4px;
      font-size: 10px;
    }

    svg {

      &:hover {
        cursor: pointer;
        color: var(--color-green);
      }
    }
  }
}
