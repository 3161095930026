.box {
  display: flex;
  flex: 1;
  border: 1px solid var(--color-gray6);
  border-radius: 6px;
  overflow: hidden;

  &.focus {
    border: 1px solid var(--color-green);
  }

  textarea {
    flex: 1;
    border: none;
    outline: none;
    padding: 8px;
    resize: none;
  }
}
