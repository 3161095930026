.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.contentWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  padding: 0px 5px 100px;

  @media screen and (min-width: 768px) {
    padding: 0px 12px 20px;
  }
}

.content {
  display: flex;
  flex: 1;
  width: inherit;
  max-width: 100%;
  overflow-y: auto;
  //background-color: var(--color-white);
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 12px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }

  h1 {
    font-size: 2em;
    margin: 0;

    @media screen and (max-width: 768px) {
      font-size: 1.5em;
      padding: 10px;
    }
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
